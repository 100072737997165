<template>
  <div class="content">
    <a-row>
      <a-form layout="inline">
        <a-form-item label="处置站点名称">
          <a-input v-model="query.disposalSiteName" placeholder="请输入处置站点名称"/>
        </a-form-item>

        <a-form-item label="街道">
          <a-select v-model="query.streetCode" placeholder="请选择街道" style="width: 200px">
            <a-select-option :key="-1" :value="undefined">全部</a-select-option>
            <a-select-option v-for="street in streetList" :key="street.value" :value="street.value">
              {{ street.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <!--        <a-form-item label="垃圾类别">-->
        <!--          <a-select v-model="query.trashType" placeholder="请选择垃圾类别"-->
        <!--                    style="width: 200px">-->
        <!--            <a-select-option v-for="(trash,index) in trashType" :key="index" :value="trash.type">-->
        <!--              <span>{{ trash.name }}</span>-->
        <!--            </a-select-option>-->
        <!--          </a-select>-->
        <!--        </a-form-item>-->

        <a-form-item label="扫描时间">
          <a-range-picker v-model="query.time"/>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="customQuery">查询</a-button>
        </a-form-item>

        <a-form-item style="color: red; font-weight: bolder;">
          默认查询前30天！
        </a-form-item>
      </a-form>
    </a-row>
      <a-row justify="end" type="flex" style="margin-top:10px;">
          <a-col :span="3">
            <download-excel :data="jsonData" :fields="jsonFields" name="处置站点信息.xls" type="xls" worksheet="处置站点信息" >
              <a-button> 下载处置站点信息 </a-button>
            </download-excel>
          </a-col>
        </a-row>
    <a-row style="margin-top: 24px;">
      <a-table :columns="columns" :data-source="data" :loading="loading"
               :pagination="pagination"
               @change="handleTableChange"
               @expand="handleExpand">

        <span slot="expandedRowRender" slot-scope="record" style="margin: 0">
          <a-row>
            <a-col :span="6">
              <a-descriptions :column="1" title="设备信息">
                <a-descriptions-item label="设备名称">
                  {{ record.device.deviceName }}
                </a-descriptions-item>
                <a-descriptions-item label="出厂编号">
                  {{ record.device.factoryCode }}
                </a-descriptions-item>
                <a-descriptions-item label="设备类型">
                  {{ record.device.deviceType }}
                </a-descriptions-item>
              </a-descriptions>
            </a-col>
          </a-row>
        </span>

        <span slot="action" slot-scope="text, record">
          <a-button type="primary" @click="$notification['warning']({ message: '敬请期待'})">详情</a-button>
        </span>
      </a-table>
    </a-row>
  </div>
</template>

<script>
import {disposalSiteTrashName} from "@/common/config";
import {queryDisposalSiteRealTime} from "@/api/disposalSite";
import _ from 'lodash'
import {queryRegionNodeChild} from "@/api/region";
import moment from "moment";
import {formatToDateBegin, formatToDateEnd, formatToDateWithTime, getPreviewDate} from "@/utils/time";
import {mapState} from "vuex";
import {queryCleanHouse, queryDevice} from "@/api/village";

export default {
  name: "disposalSiteData",
  data() {
    return {
      trashType: disposalSiteTrashName,
      query: {
        disposalSiteName: undefined,
        trashType: undefined,
        streetCode: undefined,
        time: [],

        sort: undefined,
        order: undefined
      },
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showTotal: (total, range) => `总共 ${total} 条数据`,
      },
      columns: [
        {
          title: '处置站点名称',
          dataIndex: 'villageName',
          key: 'villageName',
          scopedSlots: {customRender: 'villageName'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '街道',
          dataIndex: 'streetName',
          key: 'streetName',
          scopedSlots: {customRender: 'streetName'},
          align: 'center',
        },
        // {
        //   title: '推送类型',
        //   dataIndex: 'sender',
        //   key: 'sender',
        //   scopedSlots: {customRender: 'sender'},
        //   align: 'center',
        //   // sorter: true,
        // },
        {
          title: '重量（KG）',
          align: 'center',
          children: [
            {
              title: '皮重',
              dataIndex: 'tareWeight',
              key: 'tareWeight',
              scopedSlots: {customRender: 'tareWeight'},
              align: 'center',
              // sorter: true,
            },
            {
              title: '净重',
              dataIndex: 'netWeight',
              key: 'netWeight',
              scopedSlots: {customRender: 'netWeight'},
              align: 'center',
              // sorter: true,
            },
            {
              title: '毛重',
              dataIndex: 'grossWeight',
              key: 'grossWeight',
              scopedSlots: {customRender: 'grossWeight'},
              align: 'center',
              // sorter: true,
            },
          ]
        },
        {
          title: '垃圾类别',
          dataIndex: 'typeName',
          key: 'typeName',
          scopedSlots: {customRender: 'typeName'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '行进类别',
          dataIndex: 'travelType',
          key: 'travelType',
          scopedSlots: {customRender: 'travelType'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '车牌',
          dataIndex: 'licensePlate',
          key: 'licensePlate',
          scopedSlots: {customRender: 'licensePlate'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '扫描时间',
          key: 'submitDate',
          dataIndex: 'submitDate',
          scopedSlots: {customRender: 'submitDate'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          align: 'center'
        },
      ],
      data: [],
      loading: true,
      jsonFields: {
        '处置站点名称': 'villageName',
        '垃圾类别': 'typeName'
      },
      jsonData: []
    }
  },
  mounted() {
    this.query.time = getPreviewDate(31, 0)

    this.queryDisposalSiteList()
    this.queryAllCollection()
  },
  methods: {
    queryAllCollection() {
      queryDisposalSiteRealTime({pageNo: 1, pageSize: 600}).then(res => {
         this.jsonData = []
         res.result.content.forEach(item => {
           this.jsonData.push({villageName: item.villageName, typeName: item.typeName})
         })
      })
    },
    async queryDisposalSiteList() {
      this.loading = true

      let data = await queryDisposalSiteRealTime(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryDisposalSiteList()
    },
    customQuery() {
      this.pagination.current = 1
      this.queryDisposalSiteList()
    },
    convertQuery() {
      return {
        siteName: this.query.disposalSiteName,
        typeCode: this.query.trashType,
        streetCode: this.query.streetCode,
        startTime: formatToDateBegin(this.query.time[0]),
        endTime: formatToDateBegin(this.query.time[1]),

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.pageNo + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (disposalSite, index) => {
        return {
          ...disposalSite,
          key: disposalSite.id,
          submitDate: formatToDateWithTime(disposalSite.submitDate),
          device: {},
          house: {},
        }
      })
    },
    async handleExpand(expanded, record) {
      if (expanded) {
        record.house = (await queryCleanHouse(record.houseCode)).result
        // record.device = (await queryDevice(record.deviceCode)).result
      }
    }
  },
  computed: mapState({
    streetList: state => state.user.streetList
  })
}
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>
