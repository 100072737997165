import service from './network';

export function queryDisposalSiteRealTime(data) {
    return service.request({
        url: '/disposal/site/realTime',
        method: 'post',
        data
    })
}

export function queryDisposalSiteAnalyze(data) {
    return service.request({
        url: '/disposal/site/site',
        method: 'post',
        data
    })
}
